import React from 'react'

import {
  VisbilityContainer,
  CommonPose
} from '../animations'

const variants = {
  show: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0
    }
  },
  hide: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  }
}

export const LogoGrid = props => (
  <div className="logo-grid">
    <VisbilityContainer variants={variants}>
      <ul className="d-flex list-unstyled">
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/health/'}>
              <img className="color-img" src="images/home/logos/Oasislogo.png" />
              <img className="bw-img" src="images/home/logos/grey_Oasislogo.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/orion/'}>
              <img className="color-img" src="images/home/logos/orion.png" />
              <img className="bw-img" src="images/home/logos/grey_orion.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/snappy-kraken/'}>
              <img className="color-img" src="images/home/logos/snappykrakenlogo.png" />
              <img className="bw-img" src="images/home/logos/grey_snappykrakenlogo.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/redtail/'}>
              <img className="color-img" src="images/home/logos/redtail.png" />
              <img className="bw-img" src="images/home/logos/grey_redtail.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/qb/'}>
              <img className="color-img" src="images/home/logos/quickbooks.png" />
              <img className="bw-img" src="images/home/logos/grey_quickbooks.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/sl/'}>
              <img className="color-img" src="images/home/logos/succession link.png" />
              <img className="bw-img" src="images/home/logos/grey_succession link.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/dm-website/'}>
              <img className="color-img" src="images/home/logos/prosites.png" />
              <img className="bw-img" src="images/home/logos/grey_prosites.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/finewindandluxuryhotelprivateoffering/'}>
              <img className="color-img" src="images/home/logos/proof loyalty.png" />
              <img className="bw-img" src="images/home/logos/grey_proof loyalty.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
          <a href={process.env.SITE_URL + '/marketplace/altigo/'}>
            <img className="color-img" src="images/home/logos/altigo.png" />
            <img className="bw-img" src="images/home/logos/grey_altigo.png" />
          </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/lifeyield/'}>
              <img className="color-img" src="images/home/logos/lifeyield.png" />
              <img className="bw-img" src="images/home/logos/grey_lifeyield.png" />
            </a>
          </CommonPose>
        </li>
        <li className="logo-item">
          <CommonPose>
            <a href={process.env.SITE_URL + '/marketplace/branded-magazines/'}>
              <img className="color-img" src="images/home/logos/LogoforProductPage.png" />
              <img className="bw-img" src="images/home/logos/grey_LogoforProductPage.png" />
            </a>
          </CommonPose>
        </li>
      </ul>
    </VisbilityContainer>
  </div>
)

