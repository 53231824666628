import React from 'react'
import get from 'lodash/get'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import classNames from 'classnames'

import { WordCount } from './blog-utils'

import style from './home/blog.module.scss'

const Image = ({ show, left, right, article }) => (
  <div className="article-image">
    {article.heroImage && (
      <Img alt="" className="headline" sizes={article.heroImage.sizes} />
    )}
  </div>
)

export default ({ article }) => (
  <div className={classNames('col-md-6 col-12', style.blogPost)}>
    <Link to={`/blog/${ article.slug }/`}>
      <Image article={article} />
    </Link>
    <small>
      {article.publishDate}
      <WordCount
        content={get(article, 'richDescription.json')}
        html={get(article, 'body.childMarkdownRemark.html')} />
    </small>
    <h3 className="h4 mb-30">
      <Link to={`/blog/${ article.slug }/`}>
        { article.title }
      </Link>
    </h3>
  </div>
)
