import React, { Component, useEffect } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Modal from 'react-overlays/Modal'
import Typewriter from 'typewriter-effect'
import { DashLink } from '@chalicenetwork/chalice-style'

import { LogoGrid } from '../components/home/logo-grid'
import SEO from '../components/seo'
import Testimonial from '../components/testimonials'
import BlogSample from '../components/home/blog-sample'
import Why from '../components/home/why'
import Verticals from '../components/home/verticals'

import {
  VisbilityContainer,
  CommonPose
} from '../components/animations'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const backdropStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#000',
  opacity: 0.5
}

const VideoForm = () => {
  let mounted = false
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.async = true
    script.onload = () => {
      if (!mounted) {
        window.hbspt.forms.create({
          portalId: '7233754',
          formId: 'c6cd00ca-e298-42f9-a7f3-cdcac15ae74e',
          target: '#demo-form'
        })
      }
      mounted = true
    }
    script.onerror = () => {
      console.log('Something went wrong trying to load the demo form.')
    }
    document.body.appendChild(script)
    return () => {
      mounted = false
      document.body.removeChild(script)
    }
  })
  return (
    <div className="content" id="demo-form">
    </div>
  )
}

const VideoModal = props => {
  return (
    <Modal
      show={props.show}
      className="demo-modal"
      onHide={props.onHide}
      renderBackdrop={props => (
        <div {...props} style={backdropStyle} />
      )}>
      <div className="demo">
        <h2>
          Watch a Demo
          <button className="close" onClick={props.onHide}>&times;</button>
        </h2>
        <VideoForm />
      </div>
    </Modal>
  )
}

class Intro extends Component {
  state = {
    count_up: false,
    stats_image: false,
    show_demo: false,
  }

  componentDidMount () {
    this.setState({ show: true, is_mounted: true })
    if (window.location.href.includes('watchDemo=1') && !this.state.show_demo) {
      this.setState({ show_demo: true })
    }
  }

  onChange (isVisible, key) {
    if (isVisible) {
      this.setState({ [key]: true })
    }
  }

  watchDemo = () => {
    this.setState({ show_demo: true })
  }

  render () {
    return (
      <div role="main">
        <VideoModal
          show={this.state.show_demo}
          onHide={() => this.setState({ show_demo: false })} />
        <div className="video-cta">
          <div className="container">
            <div className="col-12 col-md-8">
              <div className="content-container">
                <h1 className="mb-0">
                  Helping Financial Professionals
                </h1>
                <div className="type-wrap">
                  <div id="typed-strings">
                    <span>
                      <Typewriter
                        options={{
                          strings:
                            [
                              'Grow Quickly',
                              'Network',
                              'Build Equity',
                              'Find Resources',
                              'Save Money'
                            ],
                          deleteSpeed: 'natural',
                          // speed: 500,
                          autoStart: true,
                          loop: true,
                          delay: 20
                        }}
                      />
                    </span>
                  </div>
                  <span id="typed"></span>
                </div>
                <div className="splash-actions">
                  <button onClick={this.watchDemo} className="btn btn-blue-solid">Watch Demo</button>
                  <a href='/pricing/' className=" btn btn-white-solid">Become a Member</a>
                </div>
              </div>
            </div>
          </div>
          <div className="video-container">
            <video autoPlay muted loop>
              <source src="images/home/video/chalice-hero.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <Why />
        <hr />
        <div className="container">
          <div className="offset-cta d-block d-md-flex align-items-center">
            <div className="col-lg-offset-1 col-lg-6 col-md-7 order-1 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".2s">
              <VisbilityContainer>
                <CommonPose>
                  <h2 className="section-label">
                    Who we serve
                  </h2>
                </CommonPose>
                <CommonPose>
                  <p className="h2">
                    Solving The Unique Needs of Independent Business Owners
                  </p>
                </CommonPose>
                <CommonPose>
                  <p>
                    Chalice Network was purpose-built to provide big firm
                    benefits that help independent business owners compete,
                    grow and thrive.
                  </p>
                </CommonPose>
              </VisbilityContainer>
            </div>
            <div className="col-lg-offset-1 col-lg-4">
              <img src="images/home/Who We Serve.png" className="wow zoomIn" data-wow-duration="1.2s" />
            </div>

          </div>
        </div>
        <Verticals />
        <div className="container padding-100">
          <div className="row d-block d-md-flex py-5 margin-0 align-end-md">

            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <h2 className="section-label">
                Strategic Partners
              </h2>
              <p className="h2">
                Big solutions, bigger savings.
              </p>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <p>
                We help you save on the technology and services you need to to start, run and grow your business.
              </p>
              <DashLink href="/roi-calculator/0/"><span></span>Calculate Your Savings</DashLink>
            </div>
          </div>
          <LogoGrid />
        </div>
        <hr />
        <Testimonial />
        <BlogSample />
      </div>
    )
  }
}

class Home extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    const meta = get(
      this, 'props.data.allContentfulSiteData.edges.0.node') || {}
    return (
      <div className="home-wrapper">
        <SEO title={meta.homeTitle} description={meta.homeMetaDescription} />
        <VisbilityContainer>
          <Intro />
        </VisbilityContainer>
      </div>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulSiteData(
      limit: 1
    ) {
      edges {
        node {
          homeTitle
          homeMetaDescription
          tutorialVideoUrl
        }
      }
    }
  }
`
