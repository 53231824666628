import React from 'react'
import dayjs from 'dayjs'
import { StaticQuery, Link, graphql } from 'gatsby'

const WORDS_PER_MINUTE = 300.0

const wordCount = data => {
  let count = 0
  data.content.forEach(row => {
    if (row.nodeType === 'text') {
      // calculate number of words
      count = count + row.value.split(/\W/).length
    } else {
      if (row.content.length > 0) {
        count += wordCount(row)
      }
    }
  })
  return count
}

export const WordCount = ({ content, html }) => {
  let readTime = null
  if (content) {
    const count = wordCount(content)
    readTime = Math.round(count / WORDS_PER_MINUTE)
  } else if (html) {
    // very rough calculation...content is more accurent
    const count = html.split(/\W/).length
    readTime = Math.round(count / WORDS_PER_MINUTE)
  }
  if (readTime) {
    return (<> · {readTime} min read </>)
  } else {
    return null
  }
}

const BlogDates = props => {
  let startDate = dayjs('2019-09-01')
  const now = dayjs()
  const dates = []
  while (startDate.isBefore(now)) {
    dates.push(startDate)
    startDate = startDate.add(1, 'month')
  }
  return (
    <ul>
      { dates.reverse().map((d, i) => (
        <li key={i}>
          <Link to={`/blog/archive/${ d.format('YYYY') }/${ d.format('MM') }/`}>
            { d.format('MMMM YYYY') }
          </Link>
        </li>
      ))}
    </ul>
  )
}

const BlogTags = props => (
  <StaticQuery
    query={graphql`
      query BlogTagQuery {
        allContentfulBlogPost {
          edges {
            node {
              tags
            }
          }
        }
      }
    `}
    render={ data => {
      const tags = new Set()
      data.allContentfulBlogPost.edges.forEach(
        ({ node }) => (node.tags || []).forEach(t => tags.add(t)))
      return (
        <ul>
          { Array.from(tags).map((t, i) => (
            <li key={i}>
              <Link to={`/blog/category/${ t }/`}>
                { t }
              </Link>
            </li>
          ))}
        </ul>
      )
    }} />
)

export const BlogSidebar = () => (
  <div className="blog-sidebar">
    <h2>Categories</h2>
    <BlogTags />
    <h2>Archives</h2>
    <BlogDates />
  </div>
)
