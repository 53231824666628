import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { VisbilityContainer, ScalePose } from '../animations'

import styles from './verticals.module.scss'

const Entry = ({ image, alt, title, children }) => (
  <VisbilityContainer>
    <ScalePose className="cta-img">
      <Img
        fluid={ image.childImageSharp.fluid }
        alt={ title }
        className="wow zoomIn" data-wow-duration="1.4s"
      />
      <h2 className="h3">{ title }</h2>
      <p> { children } </p>
    </ScalePose>
  </VisbilityContainer>
)

const Content = props => {
  return (
    <div className="negative-height-cta">
      <div className="container">
        <div className="d-block d-md-flex negative-height-content">
          <div className="col-lg-offset-1 col-lg-5 col-md-6 col-12">
            <Entry image={props.ria} title="RIAs & Financial Advisors">
              Get access to the same benefits and technology offered by the big
              firms.
            </Entry>
            <Entry image={props.insurance} title="Insurance Agents">
              Diversify, grow and gain an advantage over your competition.
            </Entry>
            <Entry image={props.professionals} title="Allied Professionals">
              Use our network to connect with other professionals and bring
              value to your organization.
            </Entry>
          </div>
          <div className="col-lg-offset-1 col-lg-5 col-md-6 col-12 offset-column">
            <Entry image={props.realEstate} title="Real Estate Agents">
              Focus on servicing your customers and less on your back office.
            </Entry>
            <Entry image={props.cpa} title="CPA & Tax Professionals">
              Save money and create efficiencies within your organization.
            </Entry>
          </div>
        </div>
      </div>
    </div>
  )
}

const Verticals = props => {
  return (
    <StaticQuery
      query={ verticalQuery }
      render={ data => {
        return (
          <Content
            ria={data.ria}
            realEstate={data.realEstate}
            insurance={data.insurance}
            cpa={data.cpa}
            professionals={data.professionals}
          />
        )
      }} />
  )
}

const verticalQuery = graphql`
  query VerticalQuery {
    ria: file(
      relativePath: { eq: "home/Financial Advisors.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    realEstate: file(
      relativePath: { eq: "home/Real Estate.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    insurance: file(
      relativePath: { eq: "home/Insurance Agency.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cpa: file(
      relativePath: { eq: "home/CPA.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    professionals: file(
      relativePath: { eq: "home/Allied Professionals.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Verticals
