import React, { useState } from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { DashLink } from '@chalicenetwork/chalice-style'

import {
  VisbilityContainer,
  ScalePose,
  CommonPose
} from '../animations'

const solutionQuery = graphql`
  query {
    community: file(
      relativePath: { eq: "home/Community Icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 225) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    marketplace: file(
      relativePath: { eq: "home/Marketplace.png" }) {
      childImageSharp {
        fluid(maxWidth: 225) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mna: file(
      relativePath: { eq: "home/MA_Platform.png" }) {
      childImageSharp {
        fluid(maxWidth: 225) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Solutions = () => {
  return (
    <StaticQuery
      query={ solutionQuery }
      render={ data => {
        return (
          <div className="col-lg-offset-1 col-lg-10 col-sm-12 platforms-container fl-none">
            <div className="platforms-flex">
              <VisbilityContainer>
                <div className="col-cta" >
                  <ScalePose>
                    <Img fluid={data.marketplace.childImageSharp.fluid} />
                    <h2 className="h4">Marketplace</h2>
                    <p>
                      Explore discounts on healthcare plans, technology platforms, marketing services and more.
                    </p>
                    <DashLink href="/marketplace/"><span></span>Shop Marketplace</DashLink>
                  </ScalePose>
                  <ScalePose className="">
                    <Img fluid={data.community.childImageSharp.fluid} />
                    <h2 className="h4">Community</h2>
                    <p>
                      Tap into a network of like-minded professionals to share or solicit advice and recommendations.
                    </p>
                    <DashLink href="/community/"><span></span>About Community</DashLink>
                  </ScalePose>
                  <ScalePose className="">
                    <Img fluid={data.mna.childImageSharp.fluid} />
                    <h2 className="h4">M&A Platform</h2>
                    <p>
                      Communicate directly with buyers and sellers to position for growth.
                    </p>
                    <DashLink href="/succession-link/"><span></span>Discover SuccessionLink</DashLink>
                  </ScalePose>
                </div>
              </VisbilityContainer>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Solutions
