import React, { useState } from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import VisibilitySensor from 'react-visibility-sensor'
import classNames from 'classnames'
import CountUp from 'react-countup'

const statsQuery = graphql`
  query {
    icon: file(
      relativePath: { eq: "home/ChaliceIcon.png" }) {
      childImageSharp {
        fluid(maxWidth: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    splash: file(
      relativePath: { eq: "home/Stats CTA.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Statistics = () => {
  const onChange = (isVisible, func) => {
    if (isVisible) {
      func(true)
    }
  }
  const [ countUp, setCountUp ] = useState(false)
  const [ statsImage, setStatsImage ] = useState(false)
  return (
    <StaticQuery
      query={ statsQuery }
      render={ data => {
        return (
          <div className="stats-cta fl-none">
            <div className="chalice-icon2">
              <Img fluid={data.icon.childImageSharp.fluid} />
            </div>
            <VisibilitySensor partialVisibility={true} delayedCall={true} onChange={isVisible => { onChange(isVisible, setStatsImage) }}>
              <CSSTransition
                in={statsImage}
                timeout={1500}
                classNames="fade-in-up">
                <Img
                  className={classNames({ 'is-hidden': !statsImage })}
                  fluid={data.splash.childImageSharp.fluid} />
              </CSSTransition>
            </VisibilitySensor>
            <VisibilitySensor partialVisibility={true} delayedCall={true} onChange={isVisible => { onChange(isVisible, setCountUp) }}>
              <div className="stats-table d-block d-md-flex col-lg-offset-2 col-lg-8 col-12 px-0">
                <div className="col-md-4">
                  <div className="stat-numb">
                    <div className="stat">
                      <CountUp
                        start={0}
                        end={countUp ? 60 : 0}
                        duration={1.5}
                        separator=","
                        suffix="k"
                        className="counter-value"
                        startOnMount={false}
                        useEasing={false}
                      />
                    </div>
                    Members
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stat-numb">
                    <div className="stat">
                      <CountUp
                        start={0}
                        end={countUp ? 50 : 0}
                        duration={1.5}
                        separator=","
                        className="counter-value"
                        useEasing={false}
                      />
                    </div>
                    Partners
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stat-numb">
                    <div className="stat">
                      <CountUp
                        start={0}
                        end={countUp ? 20 : 0}
                        duration={1.5}
                        separator=","
                        suffix="k"
                        className="counter-value"
                        useEasing={false}
                      />
                    </div>
                    Average Savings
                  </div>
                </div>
              </div>
            </VisibilitySensor>
          </div>
        )
      }} />
  )
}

export default Statistics
