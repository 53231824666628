import React from 'react'

import Solutions from './solutions'
import Statistics from './statistics'
import {
  VisbilityContainer,
  CommonPose
} from '../animations'

const Why = () => {
  return (
    <div>
      <div className="grey-bg">
        <div className="container">
          <div className="p-rel">
            <img className="chalice-icon" src="images/home/ChaliceIcon.png" />
          </div>
          <div className='col-lg-offset-1 col-lg-10 col-12 fl-none why-chalice padding-100'>
            <VisbilityContainer>
              <CommonPose>
                <h2 className="section-label mt-50">
                  Why Chalice Network
                </h2>
              </CommonPose>
              <CommonPose>
                <p className="h2">
                  The membership that saves you time, money and offers
                  everything you need to start, run, grow, protect, and
                  sell your business.
                </p>
              </CommonPose>
            </VisbilityContainer>
          </div>
        </div>
      </div>
      <div className="container pb-50">
        <Solutions />
        <Statistics />
      </div>
    </div>
  )
}

export default Why
