import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'
import VisibilitySensor from 'react-visibility-sensor'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import styles from './testimonial.module.scss'

const TestimonialCarousel = ({
  testimonials, image, blueLg, blueMd, yellowSm
}) => {
  const [parallax, setParallax] = useState(false)
  const scrollHandle = () => {
    let element1 = document.querySelector('.testimonal-accent-1')
    let element2 = document.querySelector('.testimonal-accent-2')
    let element3 = document.querySelector('.testimonal-accent-3')
    let start1 = element1.getBoundingClientRect().top
    let start2 = element2.getBoundingClientRect().top
    let start3 = element3.getBoundingClientRect().top
    let px1 = window.pageYOffset - (start1 * 2 + window.innerHeight)
    let px2 = window.pageYOffset - (start2 * 2 + window.innerHeight)
    let px3 = window.pageYOffset - (start3 * 2 + window.innerHeight)

    element1.style.transform = 'translateY(' + '-' + px1 / 20 + 'px' + ')'
    element2.style.transform = 'translateY(' + '-' + px2 / 20 + 'px' + ')'
    element3.style.transform = 'translateY(' + '-' + px3 / 20 + 'px' + ')'
  }
  useEffect(() => {
    window.addEventListener('scroll', scrollHandle)
    return () => window.removeEventListener('scroll', scrollHandle)
  })
  return (
    <VisibilitySensor
      partialVisibility={true}
      delayedCall={true}
      onChange={isVisible => { if (isVisible) { setParallax(true) } }}>
      <div className={'container ' + classNames({ 'is-hidden': !parallax })}>
        <div className="mb-20 testimonials row">
          <div className="col-lg-6 hidden-xs hidden-sm hidden-md">
            <div className={styles.accentMove}>
              <div className="testimonal-accent testimonal-accent-1">
                <Img fluid={blueLg.childImageSharp.fluid} />
              </div>
            </div>
            <div className={styles.accentMove}>
              <div className="testimonal-accent testimonal-accent-2">
                <Img fluid={blueMd.childImageSharp.fluid} />
              </div>
            </div>
            <div className={styles.accentMove}>
              <div className="testimonal-accent testimonal-accent-3">
                <Img fluid={yellowSm.childImageSharp.fluid} />
              </div>
            </div>
            { image && (
              <div className={styles.pic}>
                <Img
                  fluid={image.childImageSharp.fluid}
                  alt="Image of a happy customer" />
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <Carousel autoPlay={true} showThumbs={false} interval={10000} transitionTime={150}>
              { testimonials.map(({ node }, i) => (
                <div className="testimonial-carousel" key={i}>
                  <div className="testimonial-text">
                    <h4 dangerouslySetInnerHTML={{
                      __html: node.memberQuote.childMarkdownRemark.html
                    }} />
                    { node.companyLogo && (
                      <div className="testimonal-company-logo">
                        <Img fixed={node.companyLogo.fixed} alt={ node.memberCompany } />
                      </div>
                    )}
                    <p>
                      { node.memberName }<br />
                      { node.memberTitle }<br />
                      { node.memberCompany }<br />
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  )
}

const Testimonial = props => {
  return (
    <StaticQuery
      query={ successQuery }
      render={ data => {
        const d = data.allContentfulTestimonials.edges
        const image = data.thomas
        return (
          <TestimonialCarousel
            testimonials={d}
            image={data.thomas}
            blueLg={data.blueLg}
            blueMd={data.blueMd}
            yellowSm={data.yellowSm}
          />
        )
      }} />
  )
}

const successQuery = graphql`
  query TestimonailQuery {
    thomas: file(
      relativePath: { eq: "home/Thomas-Batterman.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blueLg: file(
      relativePath: { eq: "home/triangle-blue-lg.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blueMd: file(
      relativePath: { eq: "home/triangle-blue-md.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yellowSm: file(
      relativePath: { eq: "home/triangle-yellow-sm.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulTestimonials {
      edges {
        node {
          memberName
          memberCompany
          memberTitle
          companyLogo {
            fixed(height: 75) {
             ...GatsbyContentfulFixed_withWebp
            }
          }
          memberQuote {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default Testimonial
